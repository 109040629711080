<template>
  <b-card-code title="Create customer">

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Code"
            >
              <validation-provider
                #default="{ errors }"
                name="Code"
              >
                <b-form-input
                  v-model="customer.code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Customer code "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <h5 class="text-primary font-weight-bolder">
              <feather-icon icon="FileTextIcon" />
              Buyer details
            </h5>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Name"
              label-for="Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
              >
                <b-form-input
                  v-model="customer.buyer_name"
                  placeholder="Buyer name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Address"
            >
              <validation-provider
                #default="{ errors }"
                name="Address"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="customer.buyer_address"
                  placeholder="Buyer address"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <h5 class="text-primary font-weight-bolder">
              <feather-icon icon="FileTextIcon" />
              Importer details
            </h5>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Name"
              label-for="Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
              >
                <b-form-input
                  v-model="customer.importer_name"
                  placeholder="Importer name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Address"
            >
              <validation-provider
                #default="{ errors }"
                name="Address"
              >
                <b-form-textarea
                  v-model="customer.importer_address"
                  placeholder="Importer address"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="editCustomer"
            >
              <b-spinner
                v-if="load === 'true'"
                small
              />
              Update
            </b-button>

            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  data() {
    return {
      id: '',
      required,
      load: 'false',
      customer: {},
    }
  },
  created() {
    this.id = this.$route.params.id
    this.getCustomer()
  },

  methods: {
    async getCustomer() {
      const { data } = await this.$http.get(`/customers/detail/${this.id}/`)
      this.customer = data
    },
    async editCustomer() {
      this.load = 'true'
      await this.$http
        .put(
          `/customers/update/${this.id}/`,
          this.customer,
        ).then(() => {
          this.load = 'false'
          this.$router.push('/customers/')
          setTimeout(() => {
            this.showToast('success', 'top-center', 'Customer successfully updated')
          }, 1000)
        })
        .catch(err => {
          this.load = 'false'
          setTimeout(() => {
            this.showToast('danger', 'top-center', err.toString())
          }, 1000)
        })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
